import * as React from "react"
import Sidebar from "@components/vertical-sidebar/VerticalSidebar"
import ListBuildingManagers from "@components/building-managers/List"
import Seo from "@components/seo"
import "../../styles/index.scss"

const AdditionalParams = {
  endPointName: "building-managers.getAll",
  query: 'deleteAttempt=0'
}

const BuildingManagerListPage = (props) => (
  <>
    <Seo title="Building Managers List" />
    <div className="main-wrapper">
      <Sidebar menuActive="building-managers" />
      <ListBuildingManagers
        addBtnText="Building Manager"
        menu="Building Managers" 
        parentPath="building-managers" 
        pageType="List"
        additionalParams={AdditionalParams}/>
    </div>
  </>
)

export default BuildingManagerListPage;
